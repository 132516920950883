import React, { useRef } from 'react';
import List from './List';
import { H3 } from 'components/atoms/Typography';
import { ReactComponent as Help } from 'components/icons/help.svg';
import Dialog, { useDialog } from 'components/molecules/Dialog';
import { Wallet } from 'utils/types';

type WalletListProps = {
  wallets: Wallet[];
};
const WalletList: React.FunctionComponent<WalletListProps> = ({ wallets }) => {
  const dialogRef = useRef<HTMLDivElement | null>(null);
  const dialogManager = useDialog(dialogRef);

  const iconAction = () => {
    dialogManager && dialogManager.open();
  };

  return (
    <>
      <H3 iconAction={iconAction} Icon={Help}>
        Your Web3 addresses
      </H3>
      <List wallets={wallets} />
      <Dialog ref={dialogRef}>
        <p>
          Linking your account with Web3 wallets allows us to check for your
          tokens balance on BNB Chain, and give you premium access to Bubblemaps.
        </p>
        <p>
          We DO NOT ask for transaction permissions. You'll have to sign a
          message with Metamask to prove your identity, but that is all. That
          only tells us what is your wallet address without giving us any sort
          of permission over it.
        </p>
      </Dialog>
    </>
  );
};

export default WalletList;
