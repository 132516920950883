import React, { useEffect } from 'react';
import MDCDialog from './Material/MDCDialog';
import MDCDialogContent from './Material/MDCDialogContent';
import { useDialog } from './hooks';

interface MDCEvent extends Event {
  detail: {
    action: string;
  };
}

type ConfirmationDialogProps = {
  children: React.ReactNode;
};
const ConfirmationDialog = React.forwardRef<
  HTMLDivElement,
  ConfirmationDialogProps
>(({ children }, ref) => {
  return (
    <MDCDialog displayConfirm ref={ref}>
      <MDCDialogContent>{children}</MDCDialogContent>
    </MDCDialog>
  );
});

export const useConfirmationDialog = (
  ref: React.MutableRefObject<HTMLDivElement | null>,
  onAccept: () => void
) => {
  const manager = useDialog(ref);
  useEffect(() => {
    const handleClosed = (event: MDCEvent) => {
      if (event.detail.action === 'accept') {
        onAccept();
      }
    };
    if (manager) {
      manager.listen('MDCDialog:closed', handleClosed);
    }
    return () => {
      manager?.unlisten('MDCDialog:closed', handleClosed);
    };
  }, [manager, onAccept]);

  return manager;
};

export default ConfirmationDialog;
