import React, { useRef } from 'react';
import { TextAction } from 'components/atoms/Typography';
import { ReactComponent as Metamask } from 'components/icons/metamask.svg';
import { ReactComponent as SafePal } from 'components/icons/safepal.svg';
import { ReactComponent as Trustwallet } from 'components/icons/trustwallet.svg';
import { ReactComponent as Brave } from 'components/icons/brave.svg';
import { ReactComponent as TokenPocket } from 'components/icons/tokenpocket.svg';
import { ReactComponent as Help } from 'components/icons/help.svg';
import { ReactComponent as Okx } from 'components/icons/okx.svg';
import Box from 'components/atoms/Box';
import styled from 'styled-components';

import Dialog, { useDialog } from 'components/molecules/Dialog';

const LogosContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 4px;
`;

const LogoBox = styled(Box)`
fill: ${(props) => props.theme.themeLightPink};
`;

const ActionLogoBox = styled(Box)`
fill: ${(props) => props.theme.themeOrange};
&:hover {
  fill: ${(props) => props.theme.themePink};
}
`;

const GoBackLink: React.FunctionComponent = () => {
  const dialogRef = useRef<HTMLDivElement | null>(null);
  const dialogManager = useDialog(dialogRef);

  const textAction = () => {
    dialogManager && dialogManager.open();
  };
  return (
    <>
      <LogosContainer>
      <a href="https://safepal.com/" target="_blank" rel="noreferrer">
      <LogoBox mr="8px" height="18px" width="18px">
        <SafePal />
      </LogoBox>
      </a>
      <a href="https://metamask.io/" target="_blank" rel="noreferrer">
      <LogoBox mr="8px" height="25px" width="25px">
        <Metamask />
      </LogoBox>
      </a>
      <a href="https://trustwallet.com/" target="_blank" rel="noreferrer">
      <LogoBox mr="9px" height="22px" width="22px">
        <Trustwallet />
      </LogoBox>
      </a>
      <a href="https://brave.com/" target="_blank" rel="noreferrer">
      <LogoBox mr="9px" pb="4px" height="18px" width="18px">
        <Brave />
      </LogoBox>
      </a>
      <a href="https://www.tokenpocket.pro/" target="_blank" rel="noreferrer">
      <LogoBox mr="2px" pt="7px" height="25px" width="25px">
        <TokenPocket />
      </LogoBox>
      </a>
      <a href="https://www.okx.com/web3" target="_blank" rel="noreferrer">
      <LogoBox mr="3px" height="30px" width="30px">
        <Okx />
      </LogoBox>
      </a>
      <ActionLogoBox height="18px" width="18px">
      <TextAction action={textAction}><Help /></TextAction>
      </ActionLogoBox>
      </LogosContainer>
      {/* <TextAction action={textAction}>See compatible wallets</TextAction> */}
      <Dialog ref={dialogRef}>
      <p>
          <b>The "Sign in with a Web3 wallet" option will connect to your default Web3 wallet browser extension.</b>
        </p>
        <p>
          If you are not using a supported Web3 wallet, you can
          always <b>sign in with Twitter</b> then manually link any EVM address
          to your account.
        </p>
      </Dialog>
    </>
  );
};

export default GoBackLink;
