import styled from 'styled-components';

const ContentBlock = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px 10px 50px 10px;
  box-sizing: border-box;
  text-align: center;
`;
export default ContentBlock;
