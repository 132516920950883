import React from 'react';
import Box from 'components/atoms/Box';
import LinkTwitterAccount from 'components/molecules/LinkTwitterAccount';
import { ReactComponent as Twitter } from 'components/icons/twitter.svg';
import { useLogin } from 'providers/LoginContext';
import Unlink from './Unlink';
import Header from './Header';
import styled from 'styled-components';

const TwitterNameContainer = styled.div`
  fill: ${(props) => props.theme.twitterColor};
  width: 1em;
  height: 1em;
  margin-right: 8px;
`;

const StyledBox = styled(Box)`
  color: ${(props) => props.theme.twitterColor};
`;

const TwitterAccount = () => {
  const { user } = useLogin();
  const twitterProvider = user!.providerData.find((provider) => {
    if (!provider) {
      return false;
    }
    return provider.providerId === 'twitter.com';
  });

  return (
    <>
      {twitterProvider ? (
        <>
          <Header title="Your Twitter account" />
          <StyledBox mb="15px" display="flex" alignItems="center">
            <TwitterNameContainer>
              <Twitter />
            </TwitterNameContainer>
            {twitterProvider?.displayName}
          </StyledBox>
          <Unlink />
        </>
      ) : (
        <>
          <Header title="No Twitter account Linked yet" />
          <LinkTwitterAccount user={user!} />
        </>
      )}
    </>
  );
};

export default TwitterAccount;
