import styled from 'styled-components';

import { variant } from 'styled-system';

type BaseRippleProps = {
  className?: string;
} & React.HtmlHTMLAttributes<HTMLSpanElement>;

const variants = {
  rounded: {
    borderRadius: '1000px',
  },
};

const BaseRipple: React.FunctionComponent<BaseRippleProps> = ({
  className,
  ...attributes
}: BaseRippleProps) => <span {...attributes} className={className}></span>;

type RippleProps = {
  variant?: keyof typeof variants;
};

export const Ripple = styled(BaseRipple)<RippleProps>`
  &&& {
    ${variant({
      variants: variants,
    })}
  }
`;

export default Ripple;
