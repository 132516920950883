import styled from 'styled-components';
import {
  margin,
  MarginProps,
  padding,
  PaddingProps,
  flexbox,
  FlexboxProps,
  space,
  SpaceProps,
  height,
  HeightProps,
  width,
  layout,
  LayoutProps,
  WidthProps,
} from 'styled-system';

type BoxProps = MarginProps &
  PaddingProps &
  FlexboxProps &
  SpaceProps &
  HeightProps &
  LayoutProps &
  WidthProps;

const Box = styled.div<BoxProps>`
  ${height}
  ${layout}
  ${width}
  ${padding}
  ${margin}
  ${flexbox}
  ${space}
`;

export default Box;
