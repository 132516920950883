import React, { useRef, useEffect, useState } from 'react';
import MDCDialog from './Material/MDCDialog';
import { MDCDialog as DialogManager } from '@material/dialog';
import MDCDialogContent from './Material/MDCDialogContent';

type DialogProps = {
  children: React.ReactNode;
};
const Dialog = React.forwardRef<HTMLDivElement, DialogProps>(
  ({ children }, ref) => {
    return (
      <MDCDialog ref={ref}>
        <MDCDialogContent>{children}</MDCDialogContent>
      </MDCDialog>
    );
  }
);

export const useDialog = (
  ref: React.MutableRefObject<HTMLDivElement | null>
) => {
  const [manager, setManager] = useState<DialogManager>();
  const managerRef = useRef<DialogManager | null>(null);
  useEffect(() => {
    const target = ref.current;
    if (target) {
      const dialogManager = new DialogManager(target);
      managerRef.current = dialogManager;
      setManager(dialogManager);
    }
    return () => {
      if (managerRef.current) {
        managerRef.current.destroy();
      }
    };
  }, [ref]);

  return manager;
};
export default Dialog;
