// eslint-disable-next-line import/no-webpack-loader-syntax
const baseTheme = require('sass-extract-loader?{"plugins":["sass-extract-js"]}!../src/variables.scss');

export const theme = {
  ...baseTheme,
  twitterColor: '#08a0e9',
  metamaskColor: '#ec8120',
  mdcThemeError: '#b00020',
  themeOrange: '#ec8120',
  themePink: '#F52586',
};
