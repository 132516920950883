import React from 'react';
import styled from 'styled-components';

type MDCDialogContentProps = {
  children: React.ReactNode;
  className?: string;
};
const MDCDialogContent: React.FunctionComponent<MDCDialogContentProps> = ({
  className,
  children,
}) => {
  return <div className={`mdc-dialog__content ${className}`}>{children}</div>;
};

const StyledDialogContent = styled(MDCDialogContent)<MDCDialogContentProps>`
  a:hover {
    text-decoration: underline;
  }
`;

export default StyledDialogContent;
