import React from 'react';
import Page from 'components/templates/Page';
import LoginForm from 'pages/LoginForm';
import { useLogin } from 'providers/LoginContext';

interface ProtectedAppProps {
  children: React.ReactNode;
}
const ProtectedApp: React.FunctionComponent<ProtectedAppProps> = ({
  children,
}: ProtectedAppProps) => {
  const { user, isLoading } = useLogin();
  if (isLoading) {
    return <Page />;
  } else if (!user) {
    return <LoginForm />;
  } else {
    return <>{children}</>;
  }
};

export default ProtectedApp;
