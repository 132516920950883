import React from 'react';

type DialogProps = {
  className?: string;
  displayConfirm?: boolean;
  children: React.ReactNode;
};
const MDCDialog = React.forwardRef<HTMLDivElement, DialogProps>(
  ({ className, displayConfirm, children }, ref) => {
    return (
      <div ref={ref} className={`mdc-dialog ${className ? className : ''}`}>
        <div className="mdc-dialog__container">
          <div
            className="mdc-dialog__surface"
            role="alertdialog"
            aria-modal="true"
            aria-labelledby="my-dialog-title"
            aria-describedby="my-dialog-content"
          >
            {children}
            <div className="mdc-dialog__actions">
              <button
                type="button"
                className="mdc-button mdc-dialog__button"
                data-mdc-dialog-action="discard"
                data-mdc-dialog-initial-focus
              >
                <div className="mdc-button__ripple"></div>
                <span className="mdc-button__label">
                  {displayConfirm ? 'Cancel' : 'Close'}
                </span>
              </button>
              {displayConfirm && (
                <button
                  type="button"
                  className="mdc-button mdc-dialog__button"
                  data-mdc-dialog-action="accept"
                >
                  <div className="mdc-button__ripple"></div>
                  <span className="mdc-button__label">Confirm</span>
                </button>
              )}
            </div>
          </div>
        </div>
        <div className="mdc-dialog__scrim"></div>
      </div>
    );
  }
);

MDCDialog.defaultProps = {
  displayConfirm: false,
};
export default MDCDialog;
