import React, { useEffect, useRef } from 'react';
import { MDCSlider } from '@material/slider';

type SliderProps = {
  min: number;
  max: number;
  step: number;
  customOnChange: (value: Number) => void;
} & React.ComponentProps<'input'>;

const Slider: React.FunctionComponent<SliderProps> = ({
  min,
  max,
  step,
  customOnChange,
  ...attributes
}: SliderProps) => {
  const sliderRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const sliderManager = useRef<MDCSlider | null>(null);

  useEffect(() => {
    const onChange = () => {
      sliderManager.current && customOnChange(sliderManager.current.getValue());
    };
    const sliderCurrentRef = sliderRef.current;
    if (sliderCurrentRef) {
      sliderManager.current = new MDCSlider(sliderCurrentRef);
      sliderCurrentRef.addEventListener('MDCSlider:change', onChange);
    }
    return () => {
      if (sliderManager.current) {
        sliderManager.current.destroy();
      }
      if (sliderCurrentRef) {
        sliderCurrentRef.removeEventListener('MDCSlider:change', onChange);
      }
    };
  }, [customOnChange]);

  return (
    <div
      ref={sliderRef}
      className="mdc-slider mdc-slider--discrete mdc-slider--tick-marks"
    >
      <input
        ref={inputRef}
        className="mdc-slider__input"
        type="range"
        min={min}
        max={max}
        step={step}
        aria-label="Discrete slider with tick marks demo"
        onChange={() => null}
        {...attributes}
      />
      <div className="mdc-slider__track">
        <div className="mdc-slider__track--inactive"></div>
        <div className="mdc-slider__track--active">
          <div className="mdc-slider__track--active_fill"></div>
        </div>
        <div className="mdc-slider__tick-marks"></div>
      </div>
      <div className="mdc-slider__thumb">
        <div
          className="mdc-slider__value-indicator-container"
          aria-hidden="true"
        >
          <div className="mdc-slider__value-indicator">
            <span className="mdc-slider__value-indicator-text"></span>
          </div>
        </div>
        <div className="mdc-slider__thumb-knob"></div>
      </div>
    </div>
  );
};

export default Slider;
