import React, { useEffect, useRef } from 'react';
import { MDCRipple } from '@material/ripple';
import { MDCTextField } from '@material/textfield';
import Ripple from '../Material/Ripple';
import classNames from 'classnames';

type TextFieldProps = {
  children: React.ReactNode;
  label: string;
  filled: boolean;
  invalid: boolean;
  className?: string;
} & React.ComponentProps<'input'>;

const TextField: React.FunctionComponent<TextFieldProps> = ({
  className,
  label,
  filled,
  invalid,
  children,
  ...attributes
}: TextFieldProps) => {
  const classNameLabel = classNames('mdc-text-field', className, {
    'mdc-text-field--filled': filled,
    'mdc-text-field--invalid': invalid,
  });
  const labelRef = useRef<HTMLLabelElement>(null);
  const rippleRef = useRef<MDCRipple | null>(null);
  const textFieldRef = useRef<MDCTextField | null>(null);

  useEffect(() => {
    if (labelRef.current) {
      rippleRef.current = new MDCRipple(labelRef.current);
      textFieldRef.current = new MDCTextField(labelRef.current);
    }
    return () => {
      if (rippleRef.current) {
        rippleRef.current.destroy();
      }
      if (textFieldRef.current) {
        textFieldRef.current.destroy();
      }
    };
  }, []);

  return (
    <label ref={labelRef} className={classNameLabel}>
      <Ripple className="mdc-text-field__ripple" />
      <span className="mdc-floating-label">{label}</span>
      <input className="mdc-text-field__input" {...attributes} />
      <span className="mdc-line-ripple"></span>
      {children}
    </label>
  );
};

export default TextField;
