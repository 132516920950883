import React from 'react';
import styled from 'styled-components';
import Text from 'components/atoms/Typography/Text';

type H3Props = {
  iconAction?: () => void;
  Icon?: React.ComponentType;
} & Omit<React.ComponentProps<typeof Text>, 'tag'>;

const IconContainer = styled.div`
  vertical-align: middle;
  display: inline-block;
  margin-left: 5px;
  height: 1em;
  width: 1em;
  fill: ${(props) => props.theme.themeOrange};
  cursor: pointer;
  transition: color 0.3s ease;
  &:hover {
    fill: ${(props) => props.theme.themePink};
  }
`;

const TextContainer = styled.span`
  vertical-align: middle;
`;

const H3: React.FunctionComponent<H3Props> = ({
  Icon,
  iconAction,
  children,
  ...attributes
}) => {
  return (
    <Text tag="h3" {...attributes}>
      <TextContainer>
        {children}
      </TextContainer>
      {Icon && (
        <IconContainer onClick={iconAction}>
          <Icon />
        </IconContainer>
      )}
    </Text>
  );
};

const styledH3 = styled(H3)`
  vertical-align: middle;
`;

export default styledH3;
