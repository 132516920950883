import React from 'react';
import styled from 'styled-components';
import { Formik, FormikErrors } from 'formik';
import axios from 'axios';

import Button from 'components/atoms/Button';
import Slider from 'components/atoms/Material/Slider';
import Loader from 'components/atoms/Loader';
import { useLogin } from 'providers/LoginContext';
import { AccountCredit } from 'utils/types';
import { WEEK_CREDIT_RATE } from 'utils/constants';
import { useAccountCredit, useAccountInformation } from 'utils/hooks';

type SpendCreditFormProps = {
  accountCredit: AccountCredit;
};

interface FormValues {
  nb_weeks: number;
}

const ErrorMessage = styled.div`
  margin: 4px;
  color: ${(props) => props.theme.mdcThemeError};
`;

const SubmitButton = styled(Button)`
  margin-top: 12px;
`;

const StyledLoader = styled(Loader)`
  margin: auto;
  margin-top: 12px;
`;

const SpendCreditForm: React.FunctionComponent<SpendCreditFormProps> = ({
  accountCredit: { total_credit, spent_credit, wallets },
  ...attributes
}) => {
  const { user } = useLogin();
  const creditQuery = useAccountCredit(user!);
  const accountQuery = useAccountInformation(user!);
  const availableCredit = total_credit - spent_credit;
  const maxWeeks = Math.floor(availableCredit / WEEK_CREDIT_RATE);
  const initialValues: FormValues = { nb_weeks: maxWeeks };
  return (
    <>
      {maxWeeks > 0 && (
        <Formik
          initialValues={initialValues}
          validate={(values) => {
            const errors: FormikErrors<FormValues> = {};
            if (values.nb_weeks === 0) {
              errors.nb_weeks = 'Number of weeks should be > 0';
            }
            return errors;
          }}
          onSubmit={async (values, actions) => {
            try {
              const jwt = await user!.getIdToken();
              const response = await axios({
                method: 'POST',
                url: `${process.env.REACT_APP_ACCOUNT_ENDPOINT_HOST}/spend-credit`,
                // Account Page is protected thus user is always defined
                params: { jwt: jwt, ...values },
              });
              actions.setStatus(response.data);
              creditQuery.refetch();
              accountQuery.refetch();
            } catch (error) {
              actions.setStatus({ status: 'KO' });
            }
          }}
          validateOnMount
        >
          {({
            values,
            errors,
            touched,
            setFieldValue,
            handleSubmit,
            isSubmitting,
            isValid,
            status,
            setStatus,
            setValues,
          }) => {
            const resetForm = () => {
              setStatus(null);
              setValues(initialValues);
            };
            return (
              <form onSubmit={handleSubmit} {...attributes}>
                {isSubmitting ? (
                  <StyledLoader size={50} />
                ) : status && status.status === 'OK' ? (
                  <>
                    <div>Credit has been successfully spent!</div>
                    <SubmitButton outlined={false} onClick={() => resetForm()}>
                      Use More Credit
                    </SubmitButton>
                  </>
                ) : (
                  <>
                    <Slider
                      min={0}
                      max={initialValues.nb_weeks}
                      step={1}
                      name="nb_weeks"
                      customOnChange={(value: Number) => {
                        if (values.nb_weeks !== value) {
                          setFieldValue('nb_weeks', value);
                          setStatus(null);
                        }
                      }}
                      value={values.nb_weeks}
                    />
                    <div>
                      Get <b>{values.nb_weeks}</b> week
                      {values.nb_weeks >= 2 ? 's' : ''} of premium
                    </div>
                    <SubmitButton
                      disabled={isSubmitting || !isValid}
                      type="submit"
                      outlined={true}
                    >
                      Use Credit
                    </SubmitButton>

                    {status && status.status === 'KO' ? (
                      <ErrorMessage>
                        Something went wrong.
                        <br />
                        Please check your internet connectivity and try again
                        later.
                        <br />
                        If the problem persists,{' '}
                        <a
                          href={`mailto:help@bubblemaps.io?subject=Spend%20Credit%20error&body={uid:${
                            user!.uid
                          }}`}
                        >
                          contact us
                        </a>
                        .
                      </ErrorMessage>
                    ) : null}
                  </>
                )}
              </form>
            );
          }}
        </Formik>
      )}
    </>
  );
};

const styledSpendCreditForm = styled(SpendCreditForm)`
  display: flex;
  flex-direction: column;
`;

export default styledSpendCreditForm;
