import React, { useRef } from 'react';
import styled from 'styled-components';
import { AccountInformation } from 'utils/types';
import { amountFormatter } from 'utils/format';

import Button from 'components/atoms/Button';
import { ReactComponent as Help } from 'components/icons/help.svg';
import { ReactComponent as Moonlight } from 'components/icons/moonlight.svg';

import Dialog, { useDialog } from 'components/molecules/Dialog';

type AccountBalanceProps = {
  accountInformation: AccountInformation;
  openCreditDialog: Function;
};

const BalanceContainer = styled.div`
  display: flex;
  justify-content: space-between;
  background-color: rgba(255, 255, 255, 0.05);
  padding: 12px;
  border-radius: 1000px;
  margin-top: 12px;
`;

const BalanceText = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 16px;
  @media (max-width: 480px) {
    font-size: 0.8em;
  }
`;

const StyledButton = styled(Button)`
  height: 28px;
  border: 1px #432b6c solid;
  border-width: 0 1px 1px 1px;
  border-radius: 0 0 4px 4px;
`;

const DiscreteText = styled.span`
  font-style: italic;
  color: ${(props) => props.theme.themeLightPink};
`;

const IconContainer = styled.div`
  vertical-align: middle;
  display: inline-block;
  margin-left: 5px;
  height: 1.2em;
  width: 1.2em;
  padding-bottom: 2px;
  fill: ${(props) => props.theme.themeOrange};
  cursor: pointer;
  transition: color 0.3s ease;
  &:hover {
    fill: ${(props) => props.theme.themePink};
  }
`;

const AccountBalance: React.FunctionComponent<AccountBalanceProps> = ({
  accountInformation: {
    is_moonlight_premium,
    is_moonlight_premium_holder,
    is_lemonn_premium,
    credit_status,
    whitelist_status,
    wallets,
  },
  openCreditDialog,
}) => {
  const moonlightDialogRef = useRef<HTMLDivElement | null>(null);
  const moonlightDialogManager = useDialog(moonlightDialogRef);

  const moonlight_balance = wallets.reduce(
    (total, wallet) => total + (wallet.moonlight_balance ?? 0),
    0
  );

  const moonlightIconAction = () => {
    moonlightDialogManager && moonlightDialogManager.open();
  };

  return (
    <>
      <BalanceContainer>
        <Moonlight style={{ width: '48px' }} />
        <BalanceText>
          <b>
            Your total Moonlight Token holdings:{' '}
            {amountFormatter(moonlight_balance)}
            <IconContainer onClick={moonlightIconAction}>
              <Help />
            </IconContainer>
          </b>
          {is_moonlight_premium_holder ? (
            <div>
              You have premium access to Bubblemaps. Thanks for your support!
            </div>
          ) : whitelist_status.is_whitelisted ? (
            <div>You have been granted premium access to Bubblemaps.</div>
          ) : credit_status.is_credit ? (
            <>
              <div>You used credit to get premium access to Bubblemaps.</div>
              <DiscreteText>Expiring on {credit_status.end_date}</DiscreteText>
            </>
          ) : (
            <div>You do not have premium access to Bubblemaps.</div>
          )}
        </BalanceText>
      </BalanceContainer>
      {!is_moonlight_premium_holder && !whitelist_status.is_whitelisted ? (
        <StyledButton outlined={false} onClick={() => openCreditDialog()}>
          {!credit_status.is_credit ? 'Get temporary access' : 'Extend access'}
        </StyledButton>
      ) : null}

      <Dialog ref={moonlightDialogRef}>
        <p>
          This is your total Moonlight Token holdings across your different
          linked wallets. You have to hold at least 250,000,000,000 (250
          billion) Moonlight tokens to get premium access to Bubblemaps.
        </p>
        <p>See below to link wallets to your Bubblemaps account.</p>
      </Dialog>
    </>
  );
};

export default AccountBalance;
