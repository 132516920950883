import React, { useState, useRef } from 'react';
import Error from 'components/atoms/Error';
import firebase from 'firebase';
import TwitterButton from 'components/molecules/TwitterButton';
import Dialog, { useDialog } from 'components/molecules/Dialog';
import { linkTwitterAccount } from 'utils/firebase';

type LinkTwitterAccountProps = {
  user: firebase.User;
};
const LinkTwitterAccount: React.FunctionComponent<LinkTwitterAccountProps> = ({
  user,
}) => {
  const [disabled, setDisabled] = useState(false);
  const [error, setError] = useState('');
  const dialogRef = useRef<HTMLDivElement | null>(null);
  const dialogManager = useDialog(dialogRef);

  const handleClick = async () => {
    setDisabled(true);
    try {
      await linkTwitterAccount(user);
      setError('');
      setDisabled(false);
    } catch (e) {
      if (e.code === 'auth/credential-already-in-use') {
        dialogManager?.open();
      } else {
        setError(e);
      }
      setDisabled(false);
    }
  };

  return (
    <>
      <Error>{error}</Error>
      <TwitterButton
        label="Link With Twitter"
        disabled={disabled}
        onClick={handleClick}
      />
      <Dialog ref={dialogRef}>
        <p>
          This Twitter profile is already linked to another Bubblemaps account.
        </p>

        <p>
          If you wish, you can log in to the other Bubblemaps account using
          Twitter login method, unlink the Twitter profile, and come back to
          this account to link it.
        </p>
      </Dialog>
    </>
  );
};

export default LinkTwitterAccount;
