import React from 'react';
import './App.scss';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import Authenticator from 'pages/Authenticator';
import Account from 'pages/Account';
import LinkAccounts from 'pages/LinkAccounts';
import { QueryClient, QueryClientProvider } from 'react-query';
import { defaultQueryFn } from 'utils/fetch';

const client = new QueryClient({
  defaultOptions: {
    queries: {
      queryFn: defaultQueryFn,
    },
  },
});

function App() {
  return (
    <QueryClientProvider client={client}>
      <Router>
        <Switch>
          <Route path="/authenticate">
            <Authenticator />
          </Route>

          <Route path="/link-accounts">
            <LinkAccounts />
          </Route>
          <Route>
            <Account />
          </Route>
        </Switch>
      </Router>
    </QueryClientProvider>
  );
}

export default App;
