import React from 'react';

type MDCPageProps = {
  className?: string;
};

const MDCPage: React.FunctionComponent<MDCPageProps> = ({
  className,
  children,
}) => {
  return <div className={`${className} bubblemaps-mdc`}>{children}</div>;
};

export default MDCPage;
