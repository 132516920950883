import axios from 'axios';
import { QueryKey } from 'react-query';

export const get = async (url: string): Promise<any> => {
  const rawResponse = await fetch(url);
  if (!rawResponse.ok) {
    throw new Error(rawResponse.statusText);
  }
  const response = await rawResponse.json();

  return response;
};

export const defaultQueryFn = async ({ queryKey }: { queryKey: QueryKey }) => {
  const { data } = await axios.get(
    typeof queryKey === 'string' ? queryKey : `${queryKey[0]}`
  );
  return data;
};

export interface ResponseError extends Error {
  response: {
    data: {
      code: string;
    };
  };
}
export function isResponseError(
  e: Error | ResponseError | unknown
): e is ResponseError {
  return (e as ResponseError).response !== undefined;
}
