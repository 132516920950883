import React from 'react';
import { useLogin } from 'providers/LoginContext';
import { useAccountCredit } from 'utils/hooks';
import Loader from 'components/atoms/Loader';
import CreditText from './CreditText';
import SpendCreditForm from './SpendCreditForm';

type CreditFormProps = {};

const CreditForm: React.FunctionComponent<CreditFormProps> = () => {
  const { user } = useLogin();
  // Account Page is protected thus user is always defined
  const creditQuery = useAccountCredit(user!);

  return (
    <>
      {creditQuery.isSuccess ? (
        <>
          <CreditText accountCredit={creditQuery.data} />
          <SpendCreditForm accountCredit={creditQuery.data} />
        </>
      ) : (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Loader size={80} />
        </div>
      )}
    </>
  );
};

export default CreditForm;
