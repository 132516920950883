import React, { useEffect, useRef } from 'react';
import { MDCRipple } from '@material/ripple';
import MDCButton from '../Material/Button';
import Ripple from '../Material/Ripple';

type MaterialButtonProps = {
  children: React.ReactNode;
  outlined: boolean;
  disabled?: boolean;
  variant?: 'rounded';
  type?: 'submit';
} & React.HtmlHTMLAttributes<HTMLButtonElement>;

const MaterialButton: React.FunctionComponent<MaterialButtonProps> = ({
  children,
  variant,
  ...attributes
}: MaterialButtonProps) => {
  const buttonRef = useRef<HTMLButtonElement>(null);
  const rippleRef = useRef<MDCRipple | null>(null);

  useEffect(() => {
    if (buttonRef.current) {
      rippleRef.current = new MDCRipple(buttonRef.current);
    }
    return () => {
      if (rippleRef.current) {
        rippleRef.current.destroy();
      }
    };
  }, []);

  return (
    <MDCButton variant={variant} {...attributes}>
      <Ripple variant={variant} className="mdc-button__ripple" />
      {children}
    </MDCButton>
  );
};

export default MaterialButton;
