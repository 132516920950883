import styled from 'styled-components';
import MDCDivider from './Material/MDCDivider';
import {
  height,
  HeightProps,
  WidthProps,
  width,
  margin,
  MarginProps,
} from 'styled-system';

type DividerProps = HeightProps & WidthProps & MarginProps;

const Divider = styled(MDCDivider)<DividerProps>`
  ${height}
  ${width}
  ${margin}
`;

export default Divider;
