import React, { useEffect } from 'react';
import MDCPage from './Material/MDCPage';
import styled from 'styled-components';

const Page = styled(MDCPage)`
  min-height: 100vh;
`;

type MetaDataProps = {
  title?: string;
};
const PageWithMetaData: React.FunctionComponent<MetaDataProps> = ({
  title,
  children,
}) => {
  useEffect(() => {
    document.title = title ? title : 'Accounts | Bubblemaps';
    return () => {
      document.title = 'Accounts | Bubblemaps';
    };
  }, [title]);
  return <Page>{children}</Page>;
};

export default PageWithMetaData;
