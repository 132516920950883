import React from 'react';
import styled, { keyframes } from 'styled-components';

const LoaderContainer = styled.div<{ size: string }>`
  width: ${(props) => props.size};
  height: ${(props) => props.size};
`;

const RotateIndirect = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

const RotateDirect = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(-360deg);
  }
`;

const LoaderOuter = styled.div<{ size: string; border: string }>`
  box-sizing: border-box;
  height: ${(props) => props.size};
  width: ${(props) => props.size};
  border: ${(props) => props.border} solid red;
  border-radius: 50%;
  border-color: white transparent white transparent;
  animation: ${RotateIndirect} 1.2s linear infinite;
`;

const LoaderInner = styled.div<{
  size: string;
  border: string;
  top: string;
  left: string;
}>`
  box-sizing: border-box;
  height: ${(props) => props.size};
  width: ${(props) => props.size};
  border: ${(props) => props.border} solid red;
  border-radius: 50%;
  border-color: white transparent white transparent;
  position: relative;
  top: ${(props) => props.top};
  left: ${(props) => props.left};
  animation: ${RotateDirect} 1.2s linear infinite;
`;

type LoaderProps = {
  size: number;
};

const Loader: React.FunctionComponent<LoaderProps> = ({
  size,
  ...attributes
}) => {
  var border;
  var margin;
  if (size < 30) {
    border = Math.ceil(size / 7);
    margin = 1;
  } else {
    border = 5;
    margin = 2;
  }
  const innerSize = size - 2 * (border + margin);
  const innerTop = border + margin - size;
  const innerLeft = border + margin;

  return (
    <LoaderContainer size={size + 'px'} {...attributes}>
      <LoaderOuter size={size + 'px'} border={border + 'px'}></LoaderOuter>
      <LoaderInner
        size={innerSize + 'px'}
        border={border + 'px'}
        top={innerTop + 'px'}
        left={innerLeft + 'px'}
      ></LoaderInner>
    </LoaderContainer>
  );
};

export default Loader;
