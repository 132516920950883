import React, { useContext, useState, useEffect } from 'react';
import firebase from 'utils/firebase';

type BaseContextProps = {
  updateUser: (user: firebase.User) => void;
};

type ContextPropsLoaded = {
  user: firebase.User | null;
  isLoading: false;
};

type ContextPropsLoading = {
  user: undefined;
  isLoading: true;
};

type AuthStateProps = ContextPropsLoading | ContextPropsLoaded;
type ContextProps = BaseContextProps & AuthStateProps;
const context = React.createContext<ContextProps>({
  user: undefined,
  isLoading: true,
} as ContextProps);

interface LoginContextProps {
  children: React.ReactNode;
}
const LoginContext: React.FunctionComponent<LoginContextProps> = ({
  children,
}: LoginContextProps) => {
  const [authState, setAuthState] = useState<AuthStateProps>({
    user: undefined,
    isLoading: true,
  });

  const updateUser = (user: firebase.User) => {
    setAuthState({
      user: user,
      isLoading: false,
    });
  };

  useEffect(() => {
    firebase.auth().onAuthStateChanged((recievedUser) => {
      if (recievedUser) {
        setAuthState({ user: recievedUser, isLoading: false });
      } else {
        setAuthState({ user: null, isLoading: false });
      }
    });
  }, []);

  return (
    <context.Provider value={{ ...authState, updateUser }}>
      {children}
    </context.Provider>
  );
};

export const useLogin = () => useContext(context);
export default LoginContext;
