import React from 'react';
import Link from 'components/atoms/Link';

const GoBackLink: React.FunctionComponent = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const callback = urlParams.get('callback');
  return callback ? (
    <Link href={callback} mt="10px">
      Go back without signing in.
    </Link>
  ) : (
    <></>
  );
};

export default GoBackLink;
