import { ethers, Signer } from 'ethers';

export const formatAddress = (address: string) => {
  return `${address.substring(0, 4)}...${address.substring(
    address.length - 4
  )}`.toLowerCase();
};

export let signer: undefined | Signer;
if (window.ethereum) {
  const metaMask = new ethers.providers.Web3Provider(window.ethereum);
  signer = metaMask.getSigner();
}
