import React, { useState } from 'react';
import { getAuthChallenge } from 'utils/api';
import { useHistory } from 'react-router-dom';
import { useLogin } from 'providers/LoginContext';
import { ethers } from 'ethers';

const LinkAccounts: React.FunctionComponent = () => {
  const { user, isLoading } = useLogin();
  const [pending, setPending] = useState(false);
  const history = useHistory();
  const linkAccount = async () => {
    if (!user || isLoading) {
      return;
    }
    const metaMask = new ethers.providers.Web3Provider(window.ethereum);
    // Connect to MetaMask if necessary
    await metaMask.send('eth_requestAccounts', []);
    const signer = metaMask.getSigner();
    const address = await signer.getAddress();

    // Fetch challenge from the server
    const result = await getAuthChallenge(address);

    // Sign the challenge
    const signed_challenge = await signer.signMessage(result);

    const token = await user.getIdToken();
    console.log({
      publicAddress: address,
      signature: signed_challenge,
      idToken: token,
    });
    // Verify the challenge and link the public address to current user
    await fetch('/functions/link-metamask-account', {
      method: 'POST',
      body: JSON.stringify({
        publicAddress: address,
        signature: signed_challenge,
        idToken: token,
      }),
    });
    history.push('/');
  };

  const handleClick = async () => {
    setPending(true);
    try {
      await linkAccount();
    } catch (e) {
      setPending(false);
    }
  };
  return (
    <button disabled={pending} onClick={handleClick}>
      Link meta task
    </button>
  );
};

export default LinkAccounts;
