import firebase from 'firebase';
const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
};
firebase.initializeApp(firebaseConfig);

const twitter_provider = new firebase.auth.TwitterAuthProvider();
export const signInWithTwitter = async () =>
  firebase.auth().signInWithRedirect(twitter_provider);

export const linkTwitterAccount = async (user: firebase.User) => {
  return await user.linkWithRedirect(twitter_provider);
};

export const signOut = async () => {
  return await firebase.auth().signOut();
};

export default firebase;
