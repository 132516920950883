import React from 'react';
import styled from 'styled-components';
import { Formik, FormikErrors } from 'formik';
import axios from 'axios';

import Button from 'components/atoms/Button';
import TextField from 'components/atoms/TextField';
import Loader from 'components/atoms/Loader';
import { useLogin } from 'providers/LoginContext';
import ControlAddress from './ControlAddress';
import { formatAddress } from 'utils/bsc';
import { Wallet } from 'utils/types';

type LinkFormProps = {
  wallets: Wallet[];
};

interface FormValues {
  wallet_address: string;
}

const ErrorMessage = styled.div`
  margin: 4px;
  color: ${(props) => props.theme.mdcThemeError};
`;

const SubmitButton = styled(Button)`
  margin-top: 12px;
`;

const StyledLoader = styled(Loader)`
  margin: auto;
  margin-top: 12px;
`;

const LinkForm: React.FunctionComponent<LinkFormProps> = ({
  wallets,
  ...attributes
}) => {
  const { user } = useLogin();
  const initialValues: FormValues = { wallet_address: '' };
  return (
    <Formik
      initialValues={initialValues}
      validate={(values) => {
        const errors: FormikErrors<FormValues> = {};
        if (!values.wallet_address) {
          errors.wallet_address = 'Required';
        } else if (values.wallet_address.length !== 42) {
          errors.wallet_address = 'Invalid Wallet Address';
        } else if (
          wallets
            .map((wallet) => wallet.address.toLowerCase())
            .includes(values.wallet_address.toLowerCase())
        ) {
          errors.wallet_address =
            'This wallet is already linked to your account.';
        }
        return errors;
      }}
      onSubmit={async (values, actions) => {
        try {
          const response = await axios({
            method: 'POST',
            url: `${process.env.REACT_APP_ACCOUNT_ENDPOINT_HOST}/init-manual-link`,
            // Account  is protected thus user is always defined
            params: { uid: user!.uid, ...values },
          });
          actions.setStatus(response.data);
        } catch (error) {
          actions.setStatus({ status: 'KO' });
        }
      }}
      validateOnMount
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
        isValid,
        status,
        setStatus,
      }) => {
        const customHandleChange = (e: React.ChangeEvent<any>) => {
          handleChange(e);
          setStatus(null);
        };
        const errorMessage =
          errors.wallet_address &&
          touched.wallet_address &&
          errors.wallet_address;
        return (
          <form onSubmit={handleSubmit} {...attributes}>
            <TextField
              type="text"
              name="wallet_address"
              onChange={customHandleChange}
              onBlur={handleBlur}
              value={values.wallet_address}
              filled={true}
              invalid={!!errorMessage}
              label="Wallet Address"
            />
            <ErrorMessage>{errorMessage}</ErrorMessage>
            <SubmitButton
              disabled={isSubmitting || !isValid}
              type="submit"
              outlined={true}
            >
              Submit
            </SubmitButton>
            {isSubmitting ? <StyledLoader size={50} /> : null}
            {!isSubmitting && status ? (
              status.status === 'OK' ? (
                <div>
                  <p>The random address you have to send 1 Moonlight to is:</p>
                  <ControlAddress control_address={status.control_address} />
                  <p>
                    Make sure you use {formatAddress(values.wallet_address)} for
                    the transaction, and don't wait more than a day to do it or
                    it won't be taken into account.
                  </p>
                  <p>
                    Sometimes the BNB Chain is overloaded and the transaction fails,
                    if this is the case just increase the gas fees.
                  </p>
                  <p>
                    Once you've performed the transaction, the address will
                    automatically be linked to this account.{' '}
                    <b>
                      If it was previously linked to another account this will
                      remove it from the other account.
                    </b>
                  </p>
                  <p>
                    The validation might take up to 30 minutes after you
                    performed the transaction. If you encounter difficulties,{' '}
                    <a
                      href={`mailto:help@bubblemaps.io?subject=Manual%20link%20validation%20error&body={wallet_address:${
                        values.wallet_address
                      },uid:${user!.uid}}`}
                    >
                      contact us
                    </a>
                    .
                  </p>
                </div>
              ) : (
                <ErrorMessage>
                  Something went wrong.
                  <br />
                  Please check your internet connectivity and try again later.
                  <br />
                  If the problem persists,{' '}
                  <a
                    href={`mailto:help@bubblemaps.io?subject=Manual%20link%20form%20error&body={wallet_address:${
                      values.wallet_address
                    },uid:${user!.uid}}`}
                  >
                    contact us
                  </a>
                  .
                </ErrorMessage>
              )
            ) : null}
          </form>
        );
      }}
    </Formik>
  );
};

const styledLinkForm = styled(LinkForm)`
  display: flex;
  flex-direction: column;
`;

export default styledLinkForm;
