import React, { useContext, useRef } from 'react';
import { formatAddress } from 'utils/bsc';
import { formContext } from 'pages/LoginForm/LoginForm';
import { ethers } from 'ethers';
import { signer } from 'utils/bsc';
import firebase from 'utils/firebase';
import { useBSCAddress } from 'utils/hooks';
import MetamaskButton from 'components/molecules/MetaMaskButton';
import Dialog, { useDialog } from 'components/molecules/Dialog';

import { getAuthChallenge } from 'utils/api';

const SignInButton: React.FunctionComponent = () => {
  const { isSubmiting, submit } = useContext(formContext);
  const dialogRef = useRef<HTMLDivElement | null>(null);
  const dialogManager = useDialog(dialogRef);
  const { address } = useBSCAddress();

  const signIn = async () => {
    if (!signer) {
      if (dialogManager) dialogManager.open();
      return;
    }
    const metaMask = new ethers.providers.Web3Provider(window.ethereum);
    // Connect to MetaMask if necessary
    await metaMask.send('eth_requestAccounts', []);
    const address = await signer.getAddress();

    // Fetch challenge from the server
    const result = await getAuthChallenge(address);

    // Sign the challenge
    const signed_challenge = await signer.signMessage(result);

    // Ask the server to verify the challenge
    const verified = await fetch('/functions/verify-challenge', {
      method: 'POST',
      body: JSON.stringify({
        publicAddress: address,
        signature: signed_challenge,
      }),
    });

    // Sign in with custom token returned from the server
    const { idToken } = await verified.json();
    await firebase.auth().signInWithCustomToken(idToken);
  };

  const handleClick = async () => {
    submit(signIn);
  };

  const label = `Sign in with ${
    address ? formatAddress(address) : 'a Web3 wallet'
  }`;
  return (
    <>
      <MetamaskButton
        label={label}
        disabled={isSubmiting}
        onClick={handleClick}
      />
      <Dialog ref={dialogRef}>
        <p>No Web3 wallet is installed on this browser.</p>
        <span>Please install one </span>
        <a
          rel="noopener noreferrer"
          target="_blank"
          href="https://metamask.zendesk.com/hc/en-us/articles/360015489531-Getting-Started-With-MetaMask"
        >
          (like Metamask)
        </a>
        <span> to be able to sign in with a wallet.</span>
      </Dialog>
    </>
  );
};
export default SignInButton;
