import React, { useRef, useState } from 'react';
import Error from 'components/atoms/Error';
import { isResponseError } from 'utils/fetch';
import { useLinkMetaMask } from 'utils/hooks';
import { useBSCAddress } from 'utils/hooks';
import { formatAddress } from 'utils/bsc';
import MetaMaskButton from 'components/molecules/MetaMaskButton';
import { useConfirmationDialog } from 'components/molecules/Dialog';
import ConfirmationDialog from 'components/molecules/Dialog/ConfirmationDialog';
import Dialog, { useDialog } from 'components/molecules/Dialog';
import { Wallet } from 'utils/types';

type LinkMetaMaskProps = {
  wallets: Wallet[];
};

const LinkMetaMask: React.FunctionComponent<LinkMetaMaskProps> = ({
  wallets,
}) => {
  const { address } = useBSCAddress();
  const [error, setError] = useState<string>('');
  const linkMutation = useLinkMetaMask();
  const confirmationDialogRef = useRef<HTMLDivElement | null>(null);
  const confirmationDialogManager = useConfirmationDialog(
    confirmationDialogRef,
    () => linkMutation.mutate({ force: true })
  );
  const dialogRef = useRef<HTMLDivElement | null>(null);
  const dialogManager = useDialog(dialogRef);

  const handleError = async (e: unknown) => {
    if (isResponseError(e)) {
      if (e.response.data.code === 'E01') {
        confirmationDialogManager?.open();
      }
    } else {
      setError((e as Error)?.message);
    }
  };

  const onClick = () => {
    if (
      address &&
      wallets.map((wallet) => wallet.address).includes(address.toLowerCase())
    ) {
      dialogManager?.open();
    } else {
      linkMutation.mutate({}, { onError: handleError });
    }
  };

  const label = `Link with ${
    address ? formatAddress(address) : 'a Web3 wallet'
  }`;

  return (
    <>
      <Error>{error}</Error>
      <MetaMaskButton
        disabled={linkMutation.isLoading}
        onClick={onClick}
        label={label}
      />
      <ConfirmationDialog ref={confirmationDialogRef}>
        <b>{address ? formatAddress(address) : ''}</b> is linked to another
        Bubblemaps account. Linking it here will remove it from the other
        Bubblemaps account. You won't be able to log into this other account
        using {address ? formatAddress(address) : ''} anymore. Do you wish to
        continue?
      </ConfirmationDialog>
      <Dialog ref={dialogRef}>
        <p>
          <b>{address ? formatAddress(address) : ''}</b> is already linked to
          this Bubblemaps account.
        </p>
        <p>
          If you wish to link another Web3 address, please use your wallet
          extension to change your currently selected address.
        </p>
      </Dialog>
    </>
  );
};

export default LinkMetaMask;
