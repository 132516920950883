import React from 'react';
import styled from 'styled-components';
import Box from 'components/atoms/Box';
import Text from 'components/atoms/Typography/Text';
import { ReactComponent as BubblemapsLogo } from 'components/icons/bubblemaps-logo.svg';
import { ReactComponent as BubblemapsText } from 'components/icons/bubblemaps-text.svg';

type H1Props = Omit<React.ComponentProps<typeof Text>, 'tag'>;

const Lines = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

const Line2 = styled.span`
  font-weight: 300;
  font-size: 2rem;
  text-transform: uppercase;
  letter-spacing: 8px;
  margin-bottom: -20px;
  margin-top: 10px;
  opacity: 0.6;
  @media (max-width: 480px) {
    letter-spacing: 4px;
  }
`;

const H1: React.FunctionComponent<H1Props> = ({ className, ...attributes }) => {
  return (
    <Text tag="h1" className={className} {...attributes}>
      <Lines>
        <Box mb={['20px', '20px']} height={['60px', '60px']}>
          <BubblemapsLogo style={{ height: '100%' }} />
        </Box>
        <Box mb={['10px', '10px']} height={['25px', '25px']}>
          <BubblemapsText style={{ height: '100%' }} />
        </Box>
        <Line2>Accounts</Line2>
      </Lines>
    </Text>
  );
};

const StyledH1 = styled(H1)`
  display: flex;
  align-items: center;
  padding: 0 10px;
`;

export default StyledH1;
