function toFixedNoRounding(amount: number, n: number) {
  const reg = new RegExp('^-?\\d+(?:\\.\\d{0,' + n + '})?', 'g');
  const m = amount.toString().match(reg);
  if (!m) {
    return '?';
  }
  const a = m[0];
  const dot = a.indexOf('.');
  if (dot === -1) {
    // integer
    return a;
  }
  if (n === 0) {
    return a.slice(0, -1);
  }
  const b = n - (a.length - dot) + 1;
  return b > 0 ? a + '0'.repeat(b) : a;
}

export function amountFormatter(amount: number) {
  if (amount < 1e3) {
    return toFixedNoRounding(amount, 0);
  } else if (amount < 1e4) {
    return `${toFixedNoRounding(amount / 1e3, 1)}K`;
  } else if (amount < 1e6) {
    return `${toFixedNoRounding(amount / 1e3, 0)}K`;
  } else if (amount < 1e7) {
    return `${toFixedNoRounding(amount / 1e6, 1)}M`;
  } else if (amount < 1e9) {
    return `${toFixedNoRounding(amount / 1e6, 0)}M`;
  } else if (amount < 1e10) {
    return `${toFixedNoRounding(amount / 1e9, 1)}B`;
  } else if (amount < 1e12) {
    return `${toFixedNoRounding(amount / 1e9, 0)}B`;
  } else if (amount < 1e13) {
    return `${toFixedNoRounding(amount / 1e12, 1)}T`;
  } else {
    return `${toFixedNoRounding(amount / 1e12, 0)}T`;
  }
}

export function creditFormatter(amount: number) {
  return amountFormatter(amount / 1e9);
}
