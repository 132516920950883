import { useState, useEffect, useRef } from 'react';
import firebase from 'utils/firebase';
import { ethers } from 'ethers';
import { signer } from 'utils/bsc';
import { useHistory } from 'react-router-dom';
import { useQuery } from 'react-query';
import { useMutation, useQueryClient } from 'react-query';
import axios from 'axios';
import { getAuthChallenge } from 'utils/api';
import { useLogin } from 'providers/LoginContext';
import { AccountInformation, AccountCredit } from 'utils/types';

type Variables = { force?: boolean };

export const useBSCAddress = () => {
  const [address, setAddress] = useState<null | string>(null);
  const interval = useRef<number>();
  useEffect(() => {
    const getAddress = async () => {
      if (!signer) {
        return;
      }
      try {
        const new_address = await signer.getAddress();
        if (new_address !== address) {
          setAddress(new_address);
        }
      } catch {}
    };

    getAddress();
    interval.current = window.setInterval(getAddress, 500);
    return () => {
      window.clearInterval(interval.current);
    };
  }, [address]);
  return { address };
};

export const useAccountInformation = (user: firebase.User) => {
  const accountQuery = useQuery<AccountInformation>(
    `${process.env.REACT_APP_ACCOUNT_ENDPOINT_HOST}/get-account-data?uid=${user.uid}`
  );

  return accountQuery;
};

export const useAccountCredit = (user: firebase.User) => {
  const creditQuery = useQuery<AccountCredit>(
    `${process.env.REACT_APP_ACCOUNT_ENDPOINT_HOST}/get-account-credit?uid=${user.uid}`
  );

  return creditQuery;
};

export const useLinkMetaMask = () => {
  const history = useHistory();
  const { user, isLoading } = useLogin();
  const linkAccount = async ({ force }: Variables) => {
    if (!user || isLoading) {
      return;
    }
    const metaMask = new ethers.providers.Web3Provider(window.ethereum);
    // Connect to MetaMask if necessary
    await metaMask.send('eth_requestAccounts', []);
    const signer = metaMask.getSigner();
    const address = await signer.getAddress();

    // Fetch challenge from the server
    const challenge = await getAuthChallenge(address);

    // Sign the challenge
    const signedChallenge = await signer.signMessage(challenge);

    const token = await user.getIdToken();
    // Verify the challenge and link the public address to current user
    return await axios.post(
      `/functions/link-metamask-account?force_link=${force ?? 'false'}`,
      {
        publicAddress: address,
        signature: signedChallenge,
        idToken: token,
      }
    );
  };
  const queryClient = useQueryClient();

  const linkMutation = useMutation(linkAccount, {
    onSuccess: (data) => {
      const queryKey = `${
        process.env.REACT_APP_ACCOUNT_ENDPOINT_HOST
      }/get-account-data?uid=${user!.uid}`;
      const prev_accountInfo =
        queryClient.getQueryData<AccountInformation>(queryKey);
      if (!prev_accountInfo || !data) {
        queryClient.invalidateQueries(queryKey);
        return;
      }

      if (
        !prev_accountInfo.wallets.find(
          (wallet) => wallet.address === data.data.address
        )
      ) {
        queryClient.setQueryData(queryKey, {
          ...prev_accountInfo,
          wallets: [...prev_accountInfo.wallets, data.data],
        });
      }
      queryClient.invalidateQueries(queryKey);
      history.push('/');
    },
  });
  return linkMutation;
};
