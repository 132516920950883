import styled from 'styled-components';
import { ReactComponent as Twitter } from 'components/icons/twitter.svg';
import SignInButton from 'components/atoms/SignInButton';
import Box from 'components/atoms/Box';

const StyledButton = styled(SignInButton)`
  &:hover {
    color: ${(props) => props.theme.twitterColor};
    border-color: ${(props) => props.theme.twitterColor};
    fill: ${(props) => props.theme.twitterColor};
  }
`;

type TwitterButtonProps = {
  disabled: boolean;
  onClick: () => void;
} & React.ComponentProps<typeof StyledButton>;

const TwitterButton: React.FunctionComponent<TwitterButtonProps> = ({
  disabled,
  label,
  ...attributes
}: TwitterButtonProps) => {
  return (
    <StyledButton
      disabled={disabled}
      outlined
      variant="rounded"
      {...attributes}
    >
      <Box mr="10px" height="20px" width="20px">
        <Twitter />
      </Box>
      {label}
    </StyledButton>
  );
};

export default TwitterButton;
