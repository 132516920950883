import React from 'react';
import styled from 'styled-components';
import {
  color,
  ColorProps,
  layout,
  LayoutProps,
  flexbox,
  FlexboxProps,
} from 'styled-system';

type TitleTag = 'h3' | 'h1' | 'h2';
type TextProps = {
  className?: string;
  tag?: TitleTag;
} & React.HTMLAttributes<HTMLSpanElement>;

const Text: React.FunctionComponent<TextProps> = ({
  className,
  tag,
  children,
  ...attributes
}) => {
  const Tag = tag ? tag : 'span';
  return (
    <Tag {...attributes} className={`mdc-typography ${className}`}>
      {children}
    </Tag>
  );
};

const StyledText = styled(Text)<FlexboxProps & LayoutProps & ColorProps>`
  ${flexbox}
  ${color}
  ${layout}
`;

export default StyledText;
