import styled from 'styled-components';
import { ReactComponent as Wallet } from 'components/icons/wallet.svg';
import SignInButton from 'components/atoms/SignInButton';
import Box from 'components/atoms/Box';

const StyledButton = styled(SignInButton)`
  &:hover {
    color: ${(props) => props.theme.themePink};
    border-color: ${(props) => props.theme.themePink};
    fill: ${(props) => props.theme.themePink};
  }
`;

type MetamaskButtonProps = {
  disabled?: boolean;
  label: string;
} & React.ComponentProps<typeof StyledButton>;

const MetamaskButton: React.FunctionComponent<MetamaskButtonProps> = ({
  disabled,
  label,
  ...attributes
}) => {
  return (
    <StyledButton
      disabled={disabled}
      outlined
      variant="rounded"
      {...attributes}
    >
      <Box mr="10px" height="20px" width="20px">
        <Wallet />
      </Box>
      {label}
    </StyledButton>
  );
};

export default MetamaskButton;
