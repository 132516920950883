import React from 'react';

type DividerProps = {
  className?: string;
};

const MDCDivider: React.FunctionComponent<DividerProps> = ({ className }) => {
  return <hr className={`${className} mdc-list-divider`} />;
};

export default MDCDivider;
