import React from 'react';
import TextField from '../Material/TextField';

type MaterialTextFieldProps = {
  children?: React.ReactNode;
  label: string;
  filled: boolean;
  invalid: boolean;
} & React.ComponentProps<'input'>;

const MaterialTextField: React.FunctionComponent<MaterialTextFieldProps> = ({
  children,
  ...attributes
}: MaterialTextFieldProps) => {
  return <TextField {...attributes}>{children}</TextField>;
};

export default MaterialTextField;
