import React, { useContext } from 'react';
import TwitterButton from 'components/molecules/TwitterButton';
import { formContext } from 'pages/LoginForm/LoginForm';
import { signInWithTwitter } from 'utils/firebase';

const SignInWithTwitter: React.FunctionComponent = () => {
  const { isSubmiting, submit } = useContext(formContext);

  const handleTwitterClick = async () => {
    submit(signInWithTwitter);
  };
  return (
    <TwitterButton
      label="Sign in with twitter"
      onClick={handleTwitterClick}
      disabled={isSubmiting}
    />
  );
};

export default SignInWithTwitter;
