import React from 'react';
import styled from 'styled-components';
import Text from 'components/atoms/Typography/Text';

type TextActionProps = {
  action?: () => void;
};

const TextAction: React.FunctionComponent<TextActionProps> = ({
  action,
  children,
  ...attributes
}) => {
  return (
    <Text onClick={action} {...attributes}>
      {children}
    </Text>
  );
};

const styledTextAction = styled(TextAction)`
  vertical-align: middle;
  color: ${(props) => props.theme.themeLightPink};
  cursor: pointer;
  margin: 4px;
  &:hover {
    text-decoration: underline;
  }
`;

export default styledTextAction;
