import React from 'react';
import styled from 'styled-components';
import { variant } from 'styled-system';
import classNames from 'classnames';

type BaseButtonProps = {
  children: React.ReactNode;
  outlined?: boolean;
  className?: string;
} & React.HtmlHTMLAttributes<HTMLButtonElement>;

const BaseButton: React.FunctionComponent<BaseButtonProps> = ({
  className,
  outlined,
  children,
  ...attributes
}: BaseButtonProps) => {
  const buttonClass = classNames('mdc-button', className, {
    'mdc-button--outlined': outlined,
  });
  return (
    <button {...attributes} className={buttonClass}>
      {children}
    </button>
  );
};

type ButtonProps = {
  variant?: keyof typeof variants;
  outlined?: boolean;
};

const variants = {
  rounded: {
    borderRadius: '1000px',
    margin: '5px',
    padding: '22px',
  },
};
const Button = styled(BaseButton)<ButtonProps>`
  ${variant({ variants: variants })}
`;

export default Button;
