import React from 'react';
import styled from 'styled-components';
import Text from 'components/atoms/Typography';

const StyledText = styled(Text)`
  color: ${(props) => props.theme.themeOrange};
  font-size: 0.9em;
  margin: 8px 0;
`;

const Error: React.FunctionComponent = ({ children }) => {
  return <StyledText>{children} </StyledText>;
};

export default Error;
