import { useRef, useEffect, useState } from 'react';
import { MDCDialog as DialogManager } from '@material/dialog';

export const useDialog = (
  ref: React.MutableRefObject<HTMLDivElement | null>
) => {
  const [manager, setManager] = useState<DialogManager>();
  const managerRef = useRef<DialogManager | null>(null);
  useEffect(() => {
    const target = ref.current;
    if (target) {
      const dialogManager = new DialogManager(target);
      managerRef.current = dialogManager;
      setManager(dialogManager);
    }
    return () => {
      if (managerRef.current) {
        managerRef.current.destroy();
      }
    };
  }, [ref]);

  return manager;
};
